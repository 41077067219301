var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"algo-grey darken-1 border-radius-10",class:{
    'px-6 pt-9 pb-10': !_vm.$vuetify.breakpoint.lgAndDown,
    'px-4 pt-6 pb-8': _vm.$vuetify.breakpoint.lgAndDown,
  }},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"mr-5",attrs:{"src":require("@/assets/project-icon.png"),"max-width":!_vm.$vuetify.breakpoint.lgAndDown ? 109 : 83,"contain":""}}),_c('div',[_c('div',{staticClass:"whitelist-title-project"},[_vm._v(" "+_vm._s(_vm.vm.poolName)+" ")]),_c('div',{staticClass:"my-1 whitelist-subtitle-1"},[_vm._v("Truly Decentralised Asset Management Powered by A.I. and Analytics")]),_c('div',{staticClass:"whitelist-subtitle-2"},[_vm._v(" Featuring : On-Chain Copy-Trading. Composable Indices, and a Friendly Defi Farmer’s Market ")])])],1),_c('div',{staticClass:"algo-grey darken-2 border-radius-10 mt-6"},[_c('v-row',{staticClass:"text-center ma-0"},[_c('v-col',{staticClass:"px-0",class:{
          'pt-5 pb-6': _vm.$vuetify.breakpoint.lgAndDown,
          'pt-8 pb-9': !_vm.$vuetify.breakpoint.lgAndDown,
        },attrs:{"cols":"4"}},[_c('div',{staticClass:"fill-width px-1"},[_c('div',{staticClass:"whitelist-small-label",class:{
              'mb-2': !_vm.$vuetify.breakpoint.lgAndDown,
              'mb-1': _vm.$vuetify.breakpoint.lgAndDown,
            }},[_vm._v(" Pool size ")]),_c('div',{staticClass:"break-word whitelist-bold-label"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.vm.amount,2, 0))+" "+_vm._s(_vm.vm.tokenName))])])]),_c('v-col',{staticClass:"px-0",class:{
          'pt-5 pb-6': _vm.$vuetify.breakpoint.lgAndDown,
          'pt-8 pb-9': !_vm.$vuetify.breakpoint.lgAndDown,
        },attrs:{"cols":"4"}},[_c('div',{staticClass:"whitelist-border-side fill-width px-1"},[_c('div',{staticClass:"whitelist-small-label",class:{
              'mb-2': !_vm.$vuetify.breakpoint.lgAndDown,
              'mb-1': _vm.$vuetify.breakpoint.lgAndDown,
            }},[_vm._v(" Hard cap ")]),_c('div',{staticClass:"break-word whitelist-bold-label"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.vm.totalRaise,2, 0))+" "+_vm._s(_vm.vm.tradeToken)+" ")])])]),_c('v-col',{staticClass:"px-0",class:{
          'pt-5 pb-6': _vm.$vuetify.breakpoint.lgAndDown,
          'pt-8 pb-9': !_vm.$vuetify.breakpoint.lgAndDown,
        },attrs:{"cols":"4"}},[_c('div',{staticClass:"px-1"},[_c('div',{staticClass:"whitelist-small-label",class:{
              'mb-2': !_vm.$vuetify.breakpoint.lgAndDown,
              'mb-1': _vm.$vuetify.breakpoint.lgAndDown,
            }},[_vm._v(" Token price ")]),_c('div',{staticClass:"break-word whitelist-bold-label"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.vm.ratio,2, 0))+" "+_vm._s(_vm.vm.tradeToken))])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }